import Header from "../Components/Header/Header";
import HeroFour from "../Components/Hero/HeroFour";
import HeroTwo from "../Components/Hero/HeroTwo";
import Hero from "../Components/Hero/Hero";
import Footer from "../Components/Footer/Footer";
import About from "../Components/About/About";
import Services from "../Components/Services/Services";
import BlogFour from "../Components/Blog/BlogFour";
import OurSkill from "../Components/Skills/OurSkill";
import TeamFive from "../Components/Team/TeamFive";
import OurBrand from "../Components/OurBrands/OurBrands";
import Cta from "../Components/Cta/Cta";
import FunFact from "../Components/FunFact/FunFact";
import PortfolioFour from "../Components/Portfolio/PortfolioFour";
import Testimonial from "../Components/Testimonial/Testimonial";
import Faq from "../Components/Faq/Faq";
import FaqOne from "../Components/Faq/FaqOne";
import CompanyAddressMap from "../Components/CompanyAddressMap";
const Home = () => {
    return (
        <div className="font-oswald">
            <Header />
            <Hero />
            <About />
            <Services />
            <OurSkill />
            {/* <TeamFive /> */}
            <OurBrand />
            <Testimonial />
            <FunFact />
            <Faq />
            <Cta />
            <CompanyAddressMap />
            <Footer />
        </div>


    )
}

export default Home;


{/* <>
<HeaderOne/>
<HeroSocials/>
<HeroOne/>
<WorkProcess/>
<AboutOne/>
<ServicesOne/>
<CtaOne/>
<FeaturesOne/>
<BrandCarouselOne/>
<TestimonialOne/>
<PortfolioOne/>
<TeamOne/>
<FaqOne/>
<BlogOne/>
<Footer/>
</> */}