import React , { useEffect, useState }from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import bannerBg from '../assets/img/page-banner.jpg';
import PageBanner from '../Components/PageBanner';
import Details from '../Components/Services/Details';
import { useParams } from 'react-router-dom';
import servicesHomeData from "../Components/Services/servicesHomeData";
import Services from "../Components/Services/Services";
import OurBrand from '../Components/OurBrands/OurBrands';


const ServiceDetails = () => {
  const { param } = useParams();
  const [serverData, setServerData] = useState({});

  useEffect(() => {

    setServerData(servicesHomeData.filter(x => x.link_para.includes(param))[0])

    return () => {
    };
  }, [param]); 

  return (
    <div className="font-oswald">
        <Header/>
        <PageBanner title={serverData.title} bannerBg={serverData.img} currentPageLink='/services' currentPage='SERVICE DETAILS' />
        <Details serverData={serverData}/>
        <Services/>
        <OurBrand/>
        <Footer/>
    </div>
  )
}

export default ServiceDetails;