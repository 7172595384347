// export const companyLan = "(+974) 4402-3920"
// export const companyPh = "(+974) 6684-4910"
// export const companyTelLan = "tel:+97444023920"
// export const companyTelPh = "tel:+97466844910"
// export const projectDoneCount = 30
// export const happyClientsCount = 980
// export const skilledEmployeeCount = 50
// export const countryCoverageCount = 98
// export const companyEmail = "info@firedocintl.com"

const currentYear = new Date().getFullYear();
const yearsSince2014 = currentYear - 2020;

const GeneralInfo = {
    companyLan : "(+974) 4402-3920",
    companyPh : "(+974) 6684-4910",
    companyTelLan : "tel:+97444023920",
    companyTelPh : "tel:+97466844910",
    ExpYearCount : yearsSince2014,
    projectDoneCount : 50,
    happyClientsCount : 15,
    skilledEmployeeCount : 15,
    countryCoverageCount : 98,
    companyEmail : "info@firedocintl.com",
    companyAddress : "<p>Global Business Centre,4th Floor,</p> <p>Office R3, Corniche Road,</p> <p>Doha - Qatar</p>",
    companyWebsite : "www.firedocintl.com"
}

export default GeneralInfo;