import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import image1 from '../../assets/img/gallery/1.jpg';
import image2 from '../../assets/img/gallery/5.jpg';
import image3 from '../../assets/img/gallery/6.jpg';
import GeneralInfo from "../GeneralInfo"
import OurClient from "../OurClients/OurClients"
import ourBrandData from "../OurBrands/OurBrandData";
import clientCarouselData from "../Brands/ClientCarouselData";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation } from 'swiper';


const Details = ({ serverData }) => {

    const htmlContent = GeneralInfo.companyAddress

    const [serviceLoaded, setServiceLoaded] = useState(null);

    useEffect(() => {

        setServiceLoaded(serverData.services);

        return () => {
        };
    }, [serverData]);


    return (
        <section className="service-details-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-2 order-md-1">
                        <div className="service-details-sidebar">
                            <div className="single-service-sidebar">
                                <div className="sidebar-title">
                                    <h3>Services</h3>
                                </div>
                                <ul>
                                    {serviceLoaded &&
                                        serverData.services.map((service, i = 0) => (
                                            <li><Link to="#">{i + 1}. {service}</Link></li>
                                        ))
                                    }

                                </ul>
                            </div>
                            <div className="single-service-sidebar">
                                <div className="sidebar-title">
                                    <h3>Download Brochures</h3>
                                </div>
                                <div className="brochures-download">
                                    <a href="/FireDOC Company Profile.pdf" className="theme-btn"><i className="fal fa-file-pdf" />Download</a>
                                    {/* <a href="#" className="theme-btn"><i className="fal fa-file-word" />Download .DOC</a>
                                    <a href="#" className="theme-btn"><i className="fal fa-file-powerpoint" />Download .PPT</a> */}
                                </div>
                            </div>
                            <div className="single-service-sidebar site_info_widget">
                                <div className="sidebar-title">
                                    <h3>Contact Us</h3>
                                </div>
                                <div className="contact-us">
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-phone" />
                                        </div>
                                        <div className="contact-info">
                                            <span>Phone Number</span>
                                            <p><a href={GeneralInfo.companyTelLan}>{GeneralInfo.companyLan}</a></p>
                                            <p><a href={GeneralInfo.companyTelPh}>{GeneralInfo.companyPh}</a></p>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-envelope" />
                                        </div>
                                        <div className="contact-info">
                                            <span>Email Address</span>
                                            <p><a href={`mailto:${GeneralInfo.companyEmail}`}>{GeneralInfo.companyEmail}</a></p>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-map-marker-alt" />
                                        </div>
                                        <div className="contact-info">
                                            <span>Office Address</span>
                                            <div dangerouslySetInnerHTML={{ __html: htmlContent }} ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
                        <div className="service-details-content-wrapper pl-0 pl-md-4">


                            <section className="hero-wrapper hero-4">
                                <Swiper navigation={true} modules={[Autoplay, Navigation, EffectFade]} effect={"creative"} loop={true} autoplay={{
                                    delay: 10000,
                                    disableOnInteraction: false,
                                }}>
                                    {serviceLoaded &&
                                        serverData.servicesImg.map((data) => (
                                            // <SwiperSlide>
                                            //     <div class="service-details-container container">
                                            //         <div className='service-details-02'>
                                            //             <img src={data} alt="" />
                                            //         </div>
                                            //     </div>
                                            // </SwiperSlide>


                                            <SwiperSlide>
                                                <div class="service-details-container container">
                                                    <div className='service-details-02'>
                                                        <img src={data} alt="" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            // <SwiperSlide className="hero-slider-2">
                                            //    <img src={data} alt="" />
                                            // </SwiperSlide>
                                            // <SwiperSlide className="hero-slider-2">
                                            //     <div className="single-slide bg-cover" style={{ backgroundImage: `url(${data})` }}>
                                            //         <div className="container">
                                            //             <div className="row">
                                            //                 <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                                            //                     <div className="hero-contents">
                                            //                         <h1 className="wow fadeInLeft animated " data-wow-duration="1.3s">We keep your property safe</h1>
                                            //                         <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">Empowering Safety, Engineering Excellence: Your Trusted Partner in Fire Protection,
                                            //                             HVAC, Plumbing & Drainage, and Environmental Solutions.</p>
                                            //                         <Link to="/contact" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</Link>
                                            //                         <Link to="/projects" className="theme-btn wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".6s">our portfolio</Link>
                                            //                     </div>
                                            //                 </div>
                                            //             </div>
                                            //         </div>
                                            //     </div>
                                            // </SwiperSlide>

                                        ))
                                    }

                                </Swiper>
                            </section>

                            <div className="global-client-carousel mt-5">
                                <div className="global-title">
                                    <h4>Global Clients</h4>
                                </div>
                                <div className="global-carousel mt-4">
                                    <Swiper navigation={false} modules={[Autoplay]}
                                        slidesPerView={4}
                                        spaceBetween={30}
                                        breakpoints={{
                                            "@0.00": {
                                                slidesPerView: 2,

                                            },
                                            "@0.75": {
                                                slidesPerView: 3,

                                            },
                                            "@1.00": {
                                                slidesPerView: 3,

                                            },
                                            "@1.50": {
                                                slidesPerView: 4,
                                            },
                                        }}
                                        speed={4000}
                                        loop={true}
                                        autoplay={{
                                            delay: 200,
                                            disableOnInteraction: false,
                                        }}>
                                        {
                                            clientCarouselData.map((data) => (
                                                <SwiperSlide key={data.id}>
                                                    <div class="single-brand-logo container">
                                                        <div className='client-logo-02'>
                                                            <img src={data.brand} alt={data.name} />
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }

                                    </Swiper>
                                </div>
                            </div>
                            <div className="global-client-carousel mt-5">
                                <div className="global-title">
                                    <h4>Global Partners</h4>
                                </div>
                                <div className="global-carousel mt-4">
                                    <Swiper navigation={false} modules={[Autoplay]}
                                        slidesPerView={3}
                                        spaceBetween={30}
                                        breakpoints={{
                                            "@0.00": {
                                                slidesPerView: 2,

                                            },
                                            "@0.75": {
                                                slidesPerView: 3,

                                            },
                                            "@1.00": {
                                                slidesPerView: 3,

                                            },
                                        }}
                                        speed={4000}
                                        loop={true}
                                        autoplay={{
                                            delay: 200,
                                            disableOnInteraction: false,
                                        }}>
                                        {
                                            ourBrandData.map((data) => (
                                                <SwiperSlide key={data.id}>
                                                    <div class="single-brand-logo-02 container">
                                                        <div className='client-logo-02'>
                                                            <img src={data.img} alt={data.name} />
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }

                                    </Swiper>
                                </div>
                            </div>
                            {/* <h2>Start building construction planning</h2>
                            <p>From oil changes, filter replacements, and fluid flushes to brakes and brake repair, shocks and struts, muffler repair, tires, and wheel alignment, we’ve got you covered. Use the quick links in the gold bar to book a repair Service store. Eery single feature and page element will look amazing on the  mobile phones. It includes page templates and layouts created specifically.</p>
                            <h2>Quality Construction</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, ipsa! Repellendus at accusantium dolore rerum exercitationem iusto error? Odio deleniti in iste tenetur placeat excepturi eum officia ratione, vel aperiam?</p>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <img src={image2} alt="" />
                                </div>
                                <div className="col-md-6 col-12">
                                    <img src={image3} alt="" />
                                </div>
                            </div>
                            <h2>What You Receive?</h2>
                            <p>All kinds of laptop, desktop computer servicing center forIt is a long established fact that a reader will be distracted by the readable computer disk. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec. Predefined chunks as necessary, making this the first true generator on the Internet. Many desktop publishing packages and web page editors.</p>
                            <blockquote>
                                From oil changes, filter replacements, and fluid flushes to brakes and brake repair, shocks and struts, muffler repair
                            </blockquote> */}
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}

export default Details