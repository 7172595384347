import React from 'react'
import { Link } from 'react-router-dom'
import GeneralInfo from '../GeneralInfo'
import mainLogo from '../../assets/img/logo.png';


const MobileMenu = ({ mobileMenu, handleMobileMenu }) => {
    return (
        <section className={mobileMenu ? 'offset-menu' : 'offset-menu show'}>

            <span onClick={handleMobileMenu} id="offset-menu-close-btn"><i className="fal fa-plus"></i></span>
            <div className="offset-menu-wrapper text-white">

                <nav className="sidebar-nav">
                    <ul className="metismenu" id="mobile-menu">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">about us</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </nav>
            </div>
            <footer className="offset-menu-wrapper text-white">
                <div className="offset-menu-header">
                    <div className="offset-menu-logo">
                        <Link to="/"><img src={mainLogo} alt="logo" /></Link>
                    </div>
                </div>
                <div className="offset-menu-section">
                    <h3>Contact Info</h3>
                    <ul>
                        <li><span><i className="fal fa-map"></i></span>Fire DOC Trading & Contracting W.L.L</li>
                        <li><a href={GeneralInfo.companyTelLan}><span><i className="fal fa-phone"></i></span>{GeneralInfo.companyLan}</a></li>
                        <li><a href={GeneralInfo.companyTelPh}><span><i className="fal fa-phone"></i></span>{GeneralInfo.companyPh}</a></li>
                        <li><a href={`mailto:${GeneralInfo.companyEmail}`}><span><i className="fal fa-phone"></i></span>{GeneralInfo.companyEmail}</a></li>
                        <li><span><i className="fal fa-globe"></i></span>{GeneralInfo.companyWebsite}</li>
                    </ul>
                </div>
                <div className="offset-menu-footer">
                    <div className="offset-menu-social-icon">
                        <a href="#"><i className="fab fa-facebook-f"></i></a>
                        <a href="#"><i className="fab fa-twitter"></i></a>
                        <a href="#"><i className="fab fa-linkedin-in"></i></a>
                        <a href="#"><i className="fab fa-dribbble"></i></a>
                        <a href="#"><i className="fab fa-youtube"></i></a>
                    </div>
                </div>
            </footer>

        </section>

    )
}

export default MobileMenu