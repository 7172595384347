import { Link } from "react-router-dom";
import servicesHomeData from "./servicesHomeData";

const ServicesOne = () => {
    return (
        <section className="our-service-wrapper section-padding bg-gray">
            <div className="container">
                <div className="row mtm-30">
                    <div className="col-md-6 col-12 col-lg-4 mt-30">
                        <div className="block-contents">
                            <div className="section-title">
                                {/* <span>02. Featured Service</span> */}
                                <h2>What We Offer You</h2>
                            </div>
                            <p>We partner with leading manufacturers to bring you the most reliable and cost-efficient fire protection products available. We don't compromise on quality, ensuring you get the best value for your investment.</p>
                        </div>
                    </div>
                    {
                        servicesHomeData.map((data) => (
                            <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id}>
                                <div className="single-service-box style-1">
                                    <div className="service-bg bg-cover" style={{ backgroundImage: `url(${data.img})` }}></div>
                                    <div className="icon">
                                        <i className={`flaticon-${data.icon}`}></i>
                                    </div>
                                    <div className="contents theme-color-5">
                                        <h4><Link to={data.link_para}>{data.title}</Link>
                                            <span className="link-icon icon fal fa-link"></span>
                                        </h4>
                                        <div className="service-features-list  theme-color-5">
                                            <ul>
                                                {
                                                    data.services.map((service, i = 1) => (
                                                        <li>{service}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default ServicesOne;