import {v4 as uuidv4} from 'uuid';
import brand1 from '../../assets/images/our_brand/1.png';
import brand2  from  '../../assets/images/our_brand/2.png';
import brand3  from  '../../assets/images/our_brand/3.png';
import brand4  from  '../../assets/images/our_brand/6.png';
import brand5  from  '../../assets/images/our_brand/7.png';
import brand6  from  '../../assets/images/our_brand/8.png';
import brand7  from  '../../assets/images/our_brand/9.png';
import brand8 from  '../../assets/images/our_brand/10.png';
import brand9 from  '../../assets/images/our_brand/11.png';
import brand10 from  '../../assets/images/our_brand/12.png';
import brand11 from  '../../assets/images/our_brand/13.png';
import brand12 from  '../../assets/images/our_brand/14.png';
import brand13 from  '../../assets/images/our_brand/15.png';
import brand14 from  '../../assets/images/our_brand/16.png';
import brand15 from  '../../assets/images/our_brand/17.png';
import brand16 from  '../../assets/images/our_brand/18.png';
import brand17 from  '../../assets/images/our_brand/19.png';
import brand18 from  '../../assets/images/our_brand/20.png';
import brand19 from  '../../assets/images/our_brand/21.png';
import brand20 from  '../../assets/images/our_brand/22.png';

const OurBrandData = [
    {
        id: uuidv4(),
        img: brand1,
        category: 'ARCHITECTS',
        title: 'Our Brand',
    },

    {
        id: uuidv4(),
        img: brand2,
        category: 'DESIGN',
        title: 'Our Brand',
    },

    {
        id: uuidv4(),
        img: brand3,
        category: 'Interior',
        title: 'Our Brand',
    },

    {
        id: uuidv4(),
        img: brand4,
        category: 'Decoration',
        title: 'Our Brand',
    },

    {
        id: uuidv4(),
        img: brand5,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand6,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand7,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand8,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand9,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand10,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand11,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand12,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand13,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand14,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand15,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand16,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand17,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand18,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand19,
        category: 'Decoration',
        title: 'Our Brand',
    },
    {
        id: uuidv4(),
        img: brand20,
        category: 'Decoration',
        title: 'Our Brand',
    },

]

export default OurBrandData;