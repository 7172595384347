import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import bannerBg from '../assets/images/general-04.jpg';
import PageBanner from '../Components/PageBanner';
import ServicesOne from "../Components/Services/ServicesOne";
import ServicesCta from "../Components/Services/ServicesCta";
import IsoCert from "../Components/Features/IsoCert";
import Pricing from "../Components/Price/Pricing";
import FaqOne from "../Components/Faq/FaqOne";


const ServicePage = () => {
  return (
    <div className="font-oswald">
        <Header/>
        <PageBanner title='Our Services' bannerBg={bannerBg} currentPage='Service' />
        <ServicesOne/>
        <ServicesCta/>
        <IsoCert/>
        {/* <Pricing/> */}
        <FaqOne/>
        <Footer/>
    </div>
  )
}

export default ServicePage