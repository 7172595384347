import React from 'react'
import isoImg01 from '../../assets/images/iso/ISO_9001-2015.jpg';
import isoImg02 from '../../assets/images/iso/iso-14001-3a2015.jpg';
import isoImg03 from '../../assets/images/iso/iso-45001-2018.jpg';


const IsoCert = () => {
    return (
        <section className="best-featured-wrapper section-padding pb-5">
            <div className="container">
                <div className="row mtm-40 align-items-center justify-content-evenly">
                    <div className="col-lg-4 col-md-6 col-12 iso-gallery">
                        <img  src={isoImg01}></img>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 iso-gallery">
                        <img src={isoImg02}></img>

                    </div>
                    <div className="col-lg-4 col-md-6 col-12 iso-gallery">
                        <img src={isoImg03}></img>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default IsoCert