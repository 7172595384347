import React from 'react'
import CountUp from 'react-countup';
import bannerImg from '../../assets/images/bg-gn-01.jpg';
import { Parallax } from 'react-parallax';
import GeneralInfo from "../GeneralInfo"


const FunFact = () => {
    return (

        <Parallax blur={0} bgImage={bannerImg} bgImageAlt="the cat" strength={200}>
            <section className="fun-fact-banner bg-overlay text-white">
                <div className="container">
                    <div className="row text-center text-md-start">
                        <div className="col-lg-3 col-md-6 mt-30 col-12">
                            <div className="single-fun-fact">
                                <h2><CountUp scrollSpyOnce enableScrollSpy end={GeneralInfo.projectDoneCount} duration={3}></CountUp>+</h2>
                                <h3>PROJECT DONE</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-30 col-12">
                            <div className="single-fun-fact">
                                <h2><CountUp scrollSpyOnce enableScrollSpy end={GeneralInfo.ExpYearCount} duration={3}></CountUp>+</h2>
                                <h3>YEARS EXPERIENCE</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-30 col-12">
                            <div className="single-fun-fact">
                                <h2><CountUp scrollSpyOnce enableScrollSpy end={GeneralInfo.skilledEmployeeCount} duration={3}></CountUp>+</h2>
                                <h3>Skilled Employee</h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mt-30 col-12">
                            <div className="single-fun-fact">
                                <h2><CountUp scrollSpyOnce enableScrollSpy end={GeneralInfo.countryCoverageCount} duration={3}></CountUp>%</h2>
                                <h3>Country Coverage</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Parallax>

    )
}

export default FunFact;