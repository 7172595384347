import { Link } from "react-router-dom";
import GeneralInfo from "../GeneralInfo"
import EmailForm from "./EmailForm";

const ContactPageContents = () => {

    return (
        <div className="contact-us-wrapper section-padding">
            <div className="container">
                <div className="row eq-height">
                    <div className="col-lg-8 col-12">
                        <div className="contact-form">
                            <h2>Get in Touch</h2>
                            {/* <EmailForm></EmailForm> */}
                            <form action="#" method="GET" className="row" id="contact-form">
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <input type="text" name="name" placeholder="Name" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <input type="email" name="email" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <input type="text" name="phone" placeholder="Number" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <input type="text" name="subject" placeholder="Subject" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="single-personal-info">
                                        <textarea name="message" placeholder="message" defaultValue={""} />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <input className="submit-btn" type="submit" defaultValue="Submit Now" />
                                </div>
                            </form>
                            <span className="form-message" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="contact-us-sidebar mt-5 mt-lg-0">
                            <div className="contact-info">
                                <h2>CONTACT INFO</h2>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-email" />
                                    </div>
                                    <div className="text">
                                        <span>Email Us</span>
                                        <h5><a href={`mailto:${GeneralInfo.companyEmail}`}>{GeneralInfo.companyEmail}</a></h5>
                                    </div>
                                </div>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-phone-call-1" />
                                    </div>
                                    <div className="text">
                                        <span>Call Us</span>
                                        <h5><p><a href={GeneralInfo.companyTelLan}>{GeneralInfo.companyLan}</a></p>
                                            <p><a href={GeneralInfo.companyTelPh}>{GeneralInfo.companyPh}</a></p></h5>
                                    </div>
                                </div>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-pin" />
                                    </div>
                                    <div className="text">
                                        <span>Location</span>
                                        <h5>Global Business Centre,</h5>
                                        <p>4th Floor,Office R3,</p>
                                        <p> Corniche Road,</p>
                                        <p>Doha - Qatar</p>
                                    </div>
                                </div>
                            </div>
                            <div className="live-chat">
                                <h2>WhatsApp</h2>
                                <p>You can contact us on chat and explain your requirments</p>
                                <Link onClick={() => window.open('https://api.whatsapp.com/send?phone=97466844910&text=Hello, Need to know about FireDoc')} to=""><i className="flaticon-chatting" /> Chat to an Executive</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContactPageContents;