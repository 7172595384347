import { isValidElement } from 'react';
import {v4 as uuidv4} from 'uuid';

const faqOneData = [
    {
        id: uuidv4(),
        ques: 'What services does FireDOC offer?',
        ans: 'We specialize in fire protection, HVAC, plumbing & drainage, and environmental engineering and we offer system design, product selection, installation, maintenance, and support',
        index: 'faq1',
    },

    {
        id: uuidv4(),
        ques: 'What kind of maintenance and support do you offer?',
        ans: 'We offer a range of maintenance packages to ensure your system operates optimally. Our team is always available for 24/7 support and troubleshooting',
        index: 'faq2'
    },

    {
        id: uuidv4(),
        ques: 'What brands of fire protection products do you use?',
        ans: 'We partner with leading manufacturers to provide you with the most reliable and cost-effective options.',
        index: 'faq3'
    },

    {
        id: uuidv4(),
        ques: 'How do you ensure the quality of your installations?',
        ans: 'Our highly trained and certified technicians follow strict quality control procedures, ensuring your system meets the highest standards.',
        index: 'faq4'
    },

    {
        id: uuidv4(),
        ques: 'How do I get a quote?',
        ans: 'Simply contact us through our website or call us directly. We will be happy to discuss your needs and provide you with a free quote.',
        index: 'faq5'
    },

]

export default faqOneData;