import React from 'react'
import GeneralInfo from "../GeneralInfo"

const HeaderTop = () => {
  return (
    <div className="header-top-bar text-white d-none d-sm-block">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-9 col-sm-9">
                        <ul className="top-left-content">
                            <li><a href={`mailto:${GeneralInfo.companyEmail}`} style={{fontFamily: "'Oswald', sans-serif",textTransform:"inherit",fontWeight:"normal"}} ><i className="flaticon-paper-plane"></i>{GeneralInfo.companyEmail}</a></li>
                            <li><i className="flaticon-map"></i> Global Business Centre,4th Floor, Office R3, Corniche Road, Doha - Qatar</li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-sm-3 text-end">
                        <div className="top-social-icons">
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                            <a href="#"><i className="fab fa-twitter"></i></a>
                            <a href="#"><i className="fab fa-instagram"></i></a>
                            <a href="#"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default HeaderTop