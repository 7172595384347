import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import bannerBg from '../assets/images/hero/home_slide_1.webp';
import PageBanner from '../Components/PageBanner';
import AboutOne from '../Components/About/AboutOne';
import AboutBanner from '../Components/About/AboutBanner';
import Timeline from '../Components/Timeline';
import TeamThree from '../Components/Team/TeamThree';
import OurClients from '../Components/OurClients/OurClients';
import ClientCarousel from '../Components/Brands/ClientCarousel';
import Skill from '../Components/Skills/Skill';


const AboutPage = () => {
  return (
    <div className="font-oswald">
        <Header/>
        <PageBanner title='About us' bannerBg={bannerBg} currentPage='About' />
        <AboutOne/>
        <AboutBanner/>
        <Timeline/>
        <Skill/>
        {/* <TeamThree/> */}
        <ClientCarousel/>
        {/* <OurClients/> */}
        <Footer/>
    </div>
  )
}

export default AboutPage