import React from 'react'
import bannerBg from '../../assets/images/general-05.jpg';
import { Link } from "react-router-dom";
import GeneralInfo from "../GeneralInfo"



const ServicesCta = () => {
    return (
        <section className="pricing-cta-wrapper text-white bg-cover bg-center section-padding" style={{ backgroundImage: `url(${bannerBg})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 ps-xl-0 pe-xl-0">
                        <div className="pricing-contents">
                            <h1>We Offer Maintenenace Services</h1>
                            <div className="btn-wrapper d-flex align-items-end justify-content-center">
                                <a href="/contact">Contact Us</a>
                                <div className="btn-cta">
                                    <div className="icon">
                                        <i className="flaticon-24-hours" />
                                    </div>
                                    <div className="content">
                                        <span className="text-star">Call for support</span>
                                        <h4 className='heading-link-ph'><a href={GeneralInfo.companyTelLan}>{GeneralInfo.companyLan}</a></h4>
                                        <h4 className='heading-link-ph'><a href={GeneralInfo.companyTelPh}>{GeneralInfo.companyPh}</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ServicesCta