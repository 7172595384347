import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import clientCarouselData from "./ClientCarouselData";
import { Autoplay } from 'swiper';


const ClientCarousel = () => {
    return (
        <div className="brand-carousel-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-12">
                        <div className="brand-carousel-active">
                            <Swiper modules={[Autoplay]}
                                slidesPerView={5}
                                spaceBetween={20}

                                breakpoints={{
                                    "@0.00": {
                                        slidesPerView: 2,
                                        spaceBetween: 40,

                                    },
                                    "@0.75": {
                                        slidesPerView: 3,
                                        spaceBetween: 40,

                                    },
                                    "@1.00": {
                                        slidesPerView: 4,
                                        spaceBetween: 40,

                                    },
                                    "@1.50": {
                                        slidesPerView: 5,
                                        spaceBetween: 40,
                                    },
                                }}
                                speed={2000}
                                loop={true}
                                autoplay={{
                                    delay: 200,
                                    disableOnInteraction: false,
                                }}>
                                {
                                    clientCarouselData.map((data) => (
                                        <SwiperSlide className="single-brand-logo" key={data.id}>
                                            <div className='client-logo-about' >
                                                <img style={{padding:"12%"}}  src={data.brand} alt={data.name} />
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }

                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientCarousel