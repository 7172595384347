import { Link } from "react-router-dom";
import bannerBg from '../../assets/images/general-03.jpg';
import { Parallax } from 'react-parallax';

const Cta = () => {
    return (
        <Parallax blur={0} bgImage={bannerBg} bgImageAlt="the cat" strength={200}>
            <section className="promo-banner-cta section-padding bg-overlay bg-cover">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className="text-white">Zero Compromise on Quality</h2>
                            <h4 className="text-white">Feel free to contact us</h4>
                            <Link to='/contact' className="theme-btn style-4"><i className="icon-inbox" /> Contact Us</Link>
                        </div>
                    </div>
                </div>
            </section>
        </Parallax>
    )
}

export default Cta;

