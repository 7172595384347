import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import servicesHomeData from "./servicesHomeData";
import { Autoplay } from 'swiper';


const ServiceCarousel = () => {
    return (
        <div >
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-12">
                        <div >
                            <Swiper modules={[Autoplay]}
                                slidesPerView={3}
                                spaceBetween={10}

                                breakpoints={{
                                    "@0.00": {
                                        slidesPerView: 1,
                                        spaceBetween: 10,

                                    },
                                    "@0.50": {
                                        slidesPerView: 2,
                                        spaceBetween: 10,

                                    },
                                    "@1.00": {
                                        slidesPerView:3,
                                        spaceBetween: 10,

                                    },
                                }}
                                speed={2000}
                                loop={true}
                                autoplay={{
                                    delay: 200,
                                    disableOnInteraction: false,
                                }}>
                                {
                                    servicesHomeData.map((data) => (
                                        <SwiperSlide key={data.id}>
                                                <img   src={data.img} alt={data.title} />
                                        </SwiperSlide>
                                    ))
                                }

                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCarousel