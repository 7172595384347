import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import bannerBg from '../assets/images/home/system/environmental_solutions.webp';
import PageBanner from '../Components/PageBanner';
import ClientCarousel from "../Components/Brands/ClientCarousel";
import ContactPageContents from "../Components/Contact/ContactPageContents";
import CompanyAddressMap from "../Components/CompanyAddressMap";

const ContactPage = () => {
  return (
    <div className="font-oswald">
        <Header/>
        <PageBanner title='Contact Us' bannerBg={bannerBg} currentPage='contact' />
        <ContactPageContents/>
        <ClientCarousel/>
        <CompanyAddressMap />
        <Footer/>
    </div>
  )
}

export default ContactPage;
