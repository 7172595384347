import { Swiper, SwiperSlide } from "swiper/react";
import slide2 from '../../assets/images/hero/home_slide_1.webp';
import slide1 from '../../assets/images/hero/home_slide_2.webp';
import { Autoplay, EffectFade } from 'swiper';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import 'swiper/css/effect-fade';


const Hero = () => {
    return (
        <section className="hero-wrapper hero-1">
            <Swiper navigation={true} modules={[Autoplay, Navigation, EffectFade]} effect={"fade"} loop={true} autoplay={{
                delay: 10000,
                disableOnInteraction: false,
            }}>
                <SwiperSlide className="hero-slider-2">
                    <div className="single-slide bg-cover" style={{ backgroundImage: `url(${slide1})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                                    <div className="hero-contents">
                                        <h1 className="wow fadeInLeft animated " data-wow-duration="1.3s">We keep your Life & Property safe</h1>
                                        <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">Empowering Safety, Engineering Excellence: Your Trusted Partner in Fire Protection,
                                            HVAC, Plumbing & Drainage, and Environmental Solutions.</p>
                                        <Link to="/contact" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</Link>
                                        <Link to="/about" className="theme-btn wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".6s">our portfolio</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="hero-slider-2">
                    <div className="single-slide bg-cover" style={{ backgroundImage: `url(${slide2})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                                    <div className="hero-contents">
                                    <h1 className="wow fadeInLeft animated" data-wow-duration="1.3s">We keep your property safe</h1>
                                        <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">Empowering Safety, Engineering Excellence: Your Trusted Partner in Fire Protection,
                                            HVAC, Plumbing & Drainage, and Environmental Solutions.</p>
                                        <Link to="/contact" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">contact us</Link>
                                        <Link to="/about" className="theme-btn wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".6s">our portfolio</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default Hero;