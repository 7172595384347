import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/home3/quotes.png';

const TestimonialData = [
    {
        id: uuidv4(),
        img: img1,
        name: 'Managing Director',
        designation: 'JOJO JOSHY',
        speech: "Customer satisfaction and employee welfare are the two most important aspects FireDOC focusses for the successful operation.  The  management ensures qualified, talented and  enthusiastic professionals are recruited so that  timely delivery of quality products and projects  can be achieved. We also ensure that our          operations are carried out complying the  safety of all personnel, property and                      environment.",
    },
]

export default TestimonialData;