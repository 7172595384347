import { Link } from "react-router-dom";
import logoImg from '../../assets/img/logo-4.png';
import { useState } from "react";
import FooterCta from "./FooterCta";
import FooterOneCopyrights from "./FooterOneCopyrights";
import GeneralInfo from "../GeneralInfo"

const Footer = () => {
    const [input, setInput] = useState('');
    return (
        <>
            <footer className="footer-4 footer-wrap">
                <div className="footer-widgets-wrapper text-white">
                    

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 pe-xl-0 col-sm-6 col-12">
                                <div className="single-footer-wid site_info_widget">
                                    <div className="wid-title">
                                        <h3>Get In Touch</h3>
                                    </div>
                                    <div className="contact-us">
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fal fa-phone"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p><a href={GeneralInfo.companyTelLan}>{GeneralInfo.companyLan}</a></p>
                                                <p><a href={GeneralInfo.companyTelPh}>{GeneralInfo.companyPh}</a></p>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fal fa-envelope"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p><a href={`mailto:${GeneralInfo.companyEmail}`}>{GeneralInfo.companyEmail}</a></p>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fal fa-map-marker-alt"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p>Global Business Centre,4th Floor,</p>
                                                <p>Office R3, Corniche Road,</p>
                                                <p>Doha - Qatar</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 offset-xl-1 col-xl-3 ps-xl-5 col-12">
                                <div className="single-footer-wid">
                                    <div className="wid-title">
                                        <h3>Quick Links</h3>
                                    </div>
                                    <ul>
                                        <li><Link to="/#">Home</Link></li>
                                        <li><Link to="/about/#">About Us</Link></li>
                                        <li><Link to="/services/#">Services</Link></li>
                                        <li><Link to="/contact/#">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-6 col-xl-4 offset-xl-1 col-12">                        
                                <div className="single-footer-wid newsletter_widget">
                                    <div className="wid-title">
                                        <h3>Newsletter</h3>
                                    </div>
                                    <div className="newsletter_box">
                                        <form action="#">
                                            <i className="fal fa-envelope"></i>
                                            <input value={input} onChange={(e) => setInput(e.target.value)} type="email" placeholder="Enter email address" required/>
                                            <button onClick={(e) => e.preventDefault()} className="submit-btn" type="submit">Subscribe Now <i className="fas fa-paper-plane"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <FooterCta/>
                <FooterOneCopyrights/>
            </footer>
        </>
    )
}

export default Footer;


// <div className="container">
//                         <div className="row">
//                             <div className="col-xl-3 col-sm-6 col-12">
//                                 <div className="footer-site-info me-lg-5 mt-40">
//                                     <Link to='/'><img src={logoImg} alt="busico" /></Link>
//                                     <ul>
//                                         <li><i className="fal fa-phone"></i>&nbsp;&nbsp;<a href={GeneralInfo.companyTelLan}>{GeneralInfo.companyLan}</a></li>
//                                         <li><i className="fal fa-mobile"></i>&nbsp;&nbsp;<a href={GeneralInfo.companyTelPh}>{GeneralInfo.companyPh}</a></li>
//                                         <li><a href={`mailto:${GeneralInfo.companyEmail}`}>{GeneralInfo.companyEmail}</a></li>
//                                         <li><a href="#">Global Business Centre,4th Floor, Office R3, Corniche Road, Doha - Qatar</a></li>
//                                     </ul>
//                                 </div>
//                             </div>
//                             <div className="col-sm-6 col-xl-2 pl-xl-5 col-12">
//                                 <div className="single-footer-wid">
//                                     <div className="wid-title">
//                                         <h3>Information</h3>
//                                     </div>
//                                     <ul>
//                                         <li><Link to="/about">About Company</Link></li>
//                                         <li><Link to="/services">Our Service</Link></li>
//                                         {/* <li><Link to="/services">Internet of Things</Link></li>
//                                         <li><Link to="/services">Product Development</Link></li>
//                                         <li><Link to="/services">Industrial Automation</Link></li>
//                                         <li><Link to="/contact">Payment Getway</Link></li> */}
//                                     </ul>
//                                 </div>
//                             </div>
//                             {/* <div className="col-sm-6 col-xl-2 offset-xl-1  col-12">
//                                 <div className="single-footer-wid">
//                                     <div className="wid-title">
//                                         <h3>Quick Links</h3>
//                                     </div>
//                                     <ul>
//                                         <li><Link to="/about">About Company</Link></li>
//                                         <li><Link to="/services">Services</Link></li>
//                                         <li><Link to="/services">Internet of Things</Link></li>
//                                         <li><Link to="/services">Product Development</Link></li>
//                                         <li><Link to="/services">Industrial Automation</Link></li>
//                                         <li><Link to="/contact">Payment Getway</Link></li>
//                                     </ul>
//                                 </div>
//                             </div> */}
//                             <div className="col-sm-6 col-xl-3 offset-xl-1 col-12">                        
//                             <div className="single-footer-wid newsletter_widget style-2">
//                                 <div className="wid-title">
//                                 <h3>Newsletter</h3>
//                                 </div>
//                                 <div className="newsletter_box">
//                                 <form action="#">
//                                     <i className="fal fa-envelope" />
//                                     <input type="email" placeholder="Enter email address" required />
//                                     <button className="submit-btn" type="submit">Subscribe Now <i className="fas fa-paper-plane" /></button>
//                                 </form>
//                                 </div>
//                             </div>
//                             </div>
//                         </div>
//                     </div>