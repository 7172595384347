import React from 'react'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-parallax';

const PageBanner = ({ bannerBg, currentPage, title, currentPageLink = "#" }) => {
    return (
        <Parallax blur={1} bgImage={bannerBg} strength={-100} >
            <section className="page-banner-wrap section-padding bg-overlay-mild bg-cover" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                {/* <div className="page-banner-wrap bg-cover" style={{backgroundImage: `url(${bannerBg})`}}> */}
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="page-heading text-white">
                                <h1>{title}</h1>
                            </div>
                            <div className="breadcrumb-wrap">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link style={{color: "#ad2831"}} to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page" ><Link to={`${currentPageLink}`}>{currentPage}</Link></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </section>
        </Parallax>
    )
}

export default PageBanner