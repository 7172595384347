import React from 'react'

const Preloader = () => {
  return (
    <>
        <div id="preloader" className="preloader">
        <div className="animation-preloader">
            <div className="spinner">                
            </div>
                <div className="txt-loading">
                    <span data-text-preloader="F" className="letters-loading">
                        F
                    </span>
                    <span data-text-preloader="i" className="letters-loading">
                        i
                    </span>
                    <span data-text-preloader="r" className="letters-loading">
                       r
                    </span>
                    <span data-text-preloader="e" className="letters-loading">
                        e
                    </span>
                    <span data-text-preloader="D" className="letters-loading">
                        D
                    </span>
                    <span data-text-preloader="O" className="letters-loading">
                        O
                    </span>
                    <span data-text-preloader="C" className="letters-loading">
                        C
                    </span>
                </div>
            <p className="text-center">Loading</p>
        </div>
        <div className="loader">
            <div className="row">
                <div className="col-3 loader-section section-left">
                    <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-left">
                    <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-right">
                    <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-right">
                    <div className="bg"></div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Preloader