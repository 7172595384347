import React from 'react'

const CompanyAddressMap = () => {
    return (
        <section className="map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d901.9359389557882!2d51.55116126964008!3d25.279203998603673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE2JzQ1LjEiTiA1McKwMzMnMDYuNSJF!5e0!3m2!1sen!2sin!4v1705330414815!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            />
        </section>


    )
}

export default CompanyAddressMap