import { Link } from "react-router-dom";
import servicesData from "./servicesHomeData";

const Services = () => {
    return (
        <section className="our-service-wrapper bg-black section-padding">
            <div className="container">
                <div className="block-contents pe-0 text-white">
                    <div className="section-title-4">
                        <h2>WHAT SERVICE WE OFFER</h2>
                        <p>We partner with leading manufacturers to bring you the most reliable and cost-efficient fire protection products available. We don't compromise on quality, ensuring you get the best value for your investment.</p>
                    </div>
                </div>
                <div className="row">

                    {
                        servicesData.map((data, i = 1) => (
                            <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id}>
                                <div className="service-item-card">
                                    <div className="service-number">0{i + 1}</div>
                                    {/* <div className="icon">
                                        <i className={`${data.icon}`} />
                                    </div> */}
                                    <div className="section-img-service">
                                        <img src={data.img} alt={data.title} />
                                    </div>
                                    <div className="contents">
                                        <h4><Link to={data.link_para}>{data.title}
                                            {/* <div className="link-icon"> */}
                                                <span className="link-icon icon fal fa-link"></span>
                                            {/* </div> */}
                                        </Link></h4>



                                        <div className="service-features-list theme-color-4">
                                            <ul>
                                                {
                                                    data.services.map((service, i = 1) => (
                                                        <li>{service}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>

    )
}

export default Services;