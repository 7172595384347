import {v4 as uuidv4} from 'uuid';
import clinet1 from '../../assets/images/home/clients/galfar.png';
import clinet2 from '../../assets/images/home/clients/katara_hospitality.png';
import clinet3 from '../../assets/images/home/clients/qatar-gas.png';
import clinet4 from '../../assets/images/home/clients/qcon.png';
import clinet5 from '../../assets/images/home/clients/qnb.png';
import clinet6 from '../../assets/images/home/clients/qp.png';
import clinet7 from '../../assets/images/home/clients/qpmc.png';
import clinet8 from '../../assets/images/home/clients/qr.png';
import clinet9 from '../../assets/images/home/clients/qterminals.png';


const clientCarouselData = [
    {
        id: uuidv4(),
        brand: clinet1,
        name: 'GAlfar'
    },

    {
        id: uuidv4(),
        brand: clinet2,
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: clinet3,
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: clinet4,
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: clinet5,
        name: 'Google'
    },
    {
        id: uuidv4(),
        brand: clinet6,
        name: 'Google'
    },
    {
        id: uuidv4(),
        brand: clinet7,
        name: 'Google'
    },
    {
        id: uuidv4(),
        brand: clinet8,
        name: 'Google'
    },
    {
        id: uuidv4(),
        brand: clinet9,
        name: 'Google'
    },
    
]

export default clientCarouselData;