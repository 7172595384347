import { v4 as uuidv4 } from 'uuid';
import fire_fighting_system from "../../assets/images/home/system/fire_fighting_system.webp";
import fire_fighting_system_01 from "../../assets/images/system/firefighting/acv.jpg";
import fire_fighting_system_02 from "../../assets/images/system/firefighting/diesel fire pump.jpg";
import fire_fighting_system_03 from "../../assets/images/system/firefighting/fire pump.jpg";


import water_less_fire_suppression_system from "../../assets/images/home/system/water_less_fire_suppression_system.webp";
import water_less_fire_suppression_system_01 from "../../assets/images/system/waterless/Aeroso System.jpg";
import water_less_fire_suppression_system_02 from "../../assets/images/system/waterless/Inert Gas.jpg";
import water_less_fire_suppression_system_03 from "../../assets/images/system/waterless/fm200.png";
import water_less_fire_suppression_system_04 from "../../assets/images/system/waterless/novec.jpg";


import water_based_fire_suppression_system from "../../assets/images/home/system/water_based_fire_suppression_system.webp";
import water_based_fire_suppression_system_01 from "../../assets/images/system/waterbased/Foam-System.jpg";
import water_based_fire_suppression_system_02 from "../../assets/images/system/waterbased/hifog-watermist-system.jpg";
import water_based_fire_suppression_system_03 from "../../assets/images/system/waterbased/pre action.jpg";

import fire_alarm_system from "../../assets/images/home/system/fire_alarm_system.webp";
import fire_alarm_system_01 from "../../assets/images/system/fire alarm/Fire-Alarm.jpg";
import fire_alarm_system_02 from "../../assets/images/system/fire alarm/fa1.jpg";
import fire_alarm_system_03 from "../../assets/images/system/fire alarm/fa2.jpg";


import hvac_plumbing from "../../assets/images/home/system/hvac_plumbing.webp";
import environmental_solutions from "../../assets/images/home/system/environmental_solutions.webp";
import environmental_solution_01 from "../../assets/images/system/environment systems/19.jpg";
import environmental_solution_02 from "../../assets/images/system/environment systems/18.JPG";
import environmental_solution_03 from "../../assets/images/system/environment systems/20.JPG";
import environmental_solution_04 from "../../assets/images/system/environment systems/21.JPG";
// import environmental_solution_05 from "../../assets/images/system/environment systems/22.JPG";

import water_waste_water_system_01 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/1.jpg"
import water_waste_water_system_02 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/2.jpg"
import water_waste_water_system_03 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/3.jpg"
import water_waste_water_system_04 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/4.jpg"
import water_waste_water_system_05 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/5.jpg"
import water_waste_water_system_06 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/6.jpg"
import water_waste_water_system_07 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/7.jpg"
import water_waste_water_system_08 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/8.jpg"
import water_waste_water_system_09 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/9.jpg"
import water_waste_water_system_10 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/10.jpg"
import water_waste_water_system_11 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/11.jpg"
import water_waste_water_system_12 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/12.jpg"
import water_waste_water_system_13 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/13.jpg"
import water_waste_water_system_14 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/14.jpg"


import hvac_01 from "../../assets/images/system/HVAC/1.jpg"
import hvac_02 from "../../assets/images/system/HVAC/2.jpg"
import hvac_03 from "../../assets/images/system/HVAC/3.jpg"
import hvac_04 from "../../assets/images/system/HVAC/4.jpg"
import hvac_05 from "../../assets/images/system/HVAC/5.jpg"
// import hvac_06 from "../../assets/images/system/HVAC/6.jpg"

import plumbing_01 from "../../assets/images/system/PLUMBIG/1.jpg"
import plumbing_02 from "../../assets/images/system/PLUMBIG/2.jpg"
import plumbing_03 from "../../assets/images/system/PLUMBIG/3.jpg"

import ff_valves_and_accessories_01 from "../../assets/images/system/FF VALVES & ACCESSORIES/1.jpg"
import ff_valves_and_accessories_02 from "../../assets/images/system/FF VALVES & ACCESSORIES/2.jpg"
import ff_valves_and_accessories_03 from "../../assets/images/system/FF VALVES & ACCESSORIES/3.jpg"
import ff_valves_and_accessories_04 from "../../assets/images/system/FF VALVES & ACCESSORIES/4.jpg"
import ff_valves_and_accessories_05 from "../../assets/images/system/FF VALVES & ACCESSORIES/5.jpg"
import ff_valves_and_accessories_06 from "../../assets/images/system/FF VALVES & ACCESSORIES/6.jpg"
import ff_valves_and_accessories_07 from "../../assets/images/system/FF VALVES & ACCESSORIES/7.jpg"
import ff_valves_and_accessories_08 from "../../assets/images/system/FF VALVES & ACCESSORIES/8.jpg"
import ff_valves_and_accessories_09 from "../../assets/images/system/FF VALVES & ACCESSORIES/9.jpg"
import ff_valves_and_accessories_10 from "../../assets/images/system/FF VALVES & ACCESSORIES/10.jpg"


import fire_suppression_system_01 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/1.png"
import fire_suppression_system_02 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/2.png"
import fire_suppression_system_03 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/3.png"
import fire_suppression_system_04 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/4.png"
import fire_suppression_system_05 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/5.png"


const servicesHomeData = [
    {
        id: uuidv4(),
        icon: 'fa-yandex-international',
        img: fire_fighting_system,
        link_para: "/servicesDetails/fire_fighting_system",
        title: 'Fire Fighting System',
        desc: 'Web development service agency may sound good but it typically refers more traditional service',
        services: [
            "Sprinklers", "Valves & Accessories", "Fire Pump", "Fire Hose Reels", "Fire Extinguishers", "Fire Fighting Monitors"
        ],
        servicesImg: [
            fire_fighting_system_02,
            fire_fighting_system_01,
            fire_fighting_system_03,
            fire_fighting_system,
        ]
    },

    {
        id: uuidv4(),
        icon: 'icon-bullhorn',
        img: water_less_fire_suppression_system,
        link_para: "/servicesDetails/water_less_fire_suppression_system",
        title: 'Water Less Fire Suppression System',
        desc: 'Web development service agency may sound good but it typically refers more traditional service',
        services: [
            "FM200 System",
            "Novec 1230 System",
            "Inert Gas Systems",
            "Kitchen Hood Fire Suppression Systems",
            "Aerosol System",
        ],
        servicesImg: [
            water_less_fire_suppression_system_01,
            water_less_fire_suppression_system_02,
            water_less_fire_suppression_system_03,
            water_less_fire_suppression_system_04,
            water_less_fire_suppression_system,

            // fire_suppression_system_01,
            // fire_suppression_system_02,
            // fire_suppression_system_03,
            // fire_suppression_system_04,
            // // fire_suppression_system_05,
        ]
    },

    {
        id: uuidv4(),
        icon: 'icon-writing',
        img: water_based_fire_suppression_system,
        link_para: "/servicesDetails/water_based_fire_suppression_system",
        title: 'Water Based Fire Suppression System',
        desc: 'Web development service agency may sound good but it typically refers more traditional service',
        services: [
            "Foam System",
            "Water Deluge System",
            "Preaction Systems",
            "Water Mist Systems",
        ],
        servicesImg: [
            water_based_fire_suppression_system_01,
            water_based_fire_suppression_system_02,
            water_based_fire_suppression_system_03,
            water_based_fire_suppression_system,
        ]
    },

    {
        id: uuidv4(),
        icon: 'icon-curve',
        img: fire_alarm_system,
        link_para: "/servicesDetails/fire_alarm_system",
        title: 'Fire Alarm System',
        desc: 'Web development service agency may sound good but it typically refers more traditional service',
        services: [
            "Conventional Panel & Devices",
            "Addressable Panel & Devices",
            "PA/VA Systems",
            "Emergency & Exit Lights",
            "Notification Devices",
        ],
        servicesImg: [
            fire_alarm_system_01,
            fire_alarm_system_02,
            fire_alarm_system_03,
            fire_alarm_system
        ]
    },

    {
        id: uuidv4(),
        icon: 'icon-brainstorming',
        img: hvac_plumbing,
        link_para: "/servicesDetails/hvac_plumbing",
        title: 'HVAC & Plumbing',
        desc: 'Web development service agency may sound good but it typically refers more traditional service',
        services: [
            "HVAC Valves & Accessories",
            "Plumbing & Drainage Pumps",
            // "Preaction Systems",
            "Floor Drains",
            "Potable Water Valves & Accessories",
        ],
        servicesImg: [
            
            hvac_01,
            hvac_02,
            hvac_03,
            hvac_04,
            hvac_05,
            // hvac_06,
            plumbing_01,
            plumbing_02,
            plumbing_03,
            hvac_plumbing,
        ]

    },

    {
        id: uuidv4(),
        icon: 'icon-brainstorming',
        img: environmental_solutions,
        link_para: "/servicesDetails/environmental_solutions",
        title: 'Environmental Solutions',
        desc: 'Web development service agency may sound good but it typically refers more traditional service',
        services: [
            "Package STP With MBBR & MBR Technology",
            "Package Reverse Osmosis Water Treatment Plant",
            "Package Seawater Desalination Plant",
            "Package Effluent Treatment Plant",
            "High Pure Water Treatment System",
            "Waste & Waste Water Treament",
        ],
        servicesImg: [
            // environmental_solutions,
            environmental_solution_02,
            environmental_solution_03,
            environmental_solution_04,
            // environmental_solution_05,
            // water_waste_water_system_01,
            // water_waste_water_system_02,
            water_waste_water_system_03,
            water_waste_water_system_04,
            // water_waste_water_system_05,
            // water_waste_water_system_06,
            // water_waste_water_system_07,
            water_waste_water_system_08,
            water_waste_water_system_09,
            water_waste_water_system_10,
            water_waste_water_system_11,
            water_waste_water_system_12,
            water_waste_water_system_13,
            water_waste_water_system_14,
            environmental_solution_01,
        ]
    },

]

export default servicesHomeData;