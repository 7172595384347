import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import bannerBg from '../assets/images/general-06.jpg';
import PageBanner from '../Components/PageBanner';
import ClientCarousel from "../Components/Brands/ClientCarousel";
import FaqOne from "../Components/Faq/FaqOne";
import CtaOne from "../Components/Cta/CtaOne";

const FaqPage = () => {
  return (
    <div className="font-oswald">
        <Header/>
        <PageBanner title='FAQ Ask' bannerBg={bannerBg} currentPage='faq' />
        <FaqOne/>
        <CtaOne/>
        <ClientCarousel/>
        <Footer/>
    </div>
  )
}

export default FaqPage